<template>
  <div class="payment d-flex align-center justify-center">
    <div>
      <Loader />
      <h3>{{ $t('text.loading') }}</h3>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import axios from '@/services/axios';
import Swal from "sweetalert2";

export default {
  name: 'Payment',
  components: {
    Loader,
  },
  async mounted() {
    const { status } = this.$route.params;
    const { query: params } = this.$route;

    let message;
    try {
      const { data } = await axios.get(`/api/payment/azul/${status}`, {
        params,
      });
      message = data;
    } catch (e) {
      if (e.response?.status === 400) {
        message = e.response.data;
      } else {
        throw e;
      }
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: "contact-message-modal",
        confirmButton:
          "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
        title: "h6"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      title: this.$t('alerts.paymentInformation'),
      text: message.message,
      confirmButtonText: this.$t('alerts.btnConfirm'),
      icon: message.success ? 'success' : 'error',
    });

    await this.$router.replace({
      name: message.success ? 'order-detail' : 'orders-history',
      params: {
        order_id: message.order?.id,
      },
    });
  }
};
</script>

<style scoped>
.payment {
  height: 100vh;
}
</style>
